<template>
  <header class="shadow-sm">
    <nav class="container mx-auto p-4">
      <div class="flex flex-col md:flex-row justify-between items-center gap-4">
        <!-- Logo -->
        <NuxtLink to="/" class="font-bold text-2xl sm:text-4xl">
          11 Hero <span class="text-sm">(pre-alpha)</span>
        </NuxtLink>

        <!-- Theme Toggle and Mobile Menu Button -->
        <div class="flex items-center gap-4 md:hidden">
          <ClientOnly>
            <UButton
              :icon="
                isDark
                  ? 'i-heroicons-moon-20-solid'
                  : 'i-heroicons-sun-20-solid'
              "
              color="gray"
              variant="ghost"
              aria-label="Theme"
              @click="isDark = !isDark"
            />
          </ClientOnly>

          <!-- Mobile Menu Button -->
          <UButton
            class="md:hidden"
            color="gray"
            variant="ghost"
            icon="i-heroicons-bars-3-20-solid"
            aria-label="Menu"
            @click="toggleMenu"
          />
        </div>

        <!-- Desktop Navigation -->
        <div class="hidden md:flex items-center gap-4">
          <!-- Theme Toggle for Desktop -->
          <ClientOnly>
            <UButton
              :icon="
                isDark
                  ? 'i-heroicons-moon-20-solid'
                  : 'i-heroicons-sun-20-solid'
              "
              color="gray"
              variant="ghost"
              aria-label="Theme"
              @click="isDark = !isDark"
            />
          </ClientOnly>

          <!-- Desktop Menu -->
          <ul class="flex gap-4 items-center text-base">
            <li>
              <NuxtLink to="/" class="btn hover:bg-green-600">Home</NuxtLink>
            </li>
            <li>
              <NuxtLink to="/matches" class="btn hover:bg-green-600"
                >Matches</NuxtLink
              >
            </li>
            <li>
              <NuxtLink to="/players" class="btn hover:bg-green-600"
                >Players</NuxtLink
              >
            </li>
            <li>
              <NuxtLink to="/upload" class="btn hover:bg-green-600"
                >Upload</NuxtLink
              >
            </li>
            <li>
              <NuxtLink
                to="https://solidslime.net/cloud"
                class="btn hover:bg-green-600"
                >ETT Cloud</NuxtLink
              >
            </li>
            <li v-if="!loggedIn">
              <NuxtLink to="/login" class="btn hover:bg-green-600"
                >Login</NuxtLink
              >
            </li>
            <li v-if="loggedIn">
              <NuxtLink to="/dashboard" class="btn hover:bg-green-600"
                >Dashboard</NuxtLink
              >
            </li>
            
          </ul>
        </div>
      </div>

      <!-- Mobile Menu -->
      <div v-show="isMenuOpen" class="md:hidden">
        <ul class="py-4 space-y-2">
          <li>
            <NuxtLink to="/" class="block px-4 py-2 hover:bg-green-600"
              >Home</NuxtLink
            >
          </li>
          <li>
            <NuxtLink to="/matches" class="block px-4 py-2 hover:bg-green-600"
              >Matches</NuxtLink
            >
          </li>
          <li>
            <NuxtLink to="/players" class="block px-4 py-2 hover:bg-green-600"
              >Players</NuxtLink
            >
          </li>
          <li>
            <NuxtLink to="/upload" class="block px-4 py-2 hover:bg-green-600"
              >Upload</NuxtLink
            >
          </li>
          <li>
            <NuxtLink
              to="https://solidslime.net/cloud"
              class="block px-4 py-2 hover:bg-green-600"
              >ETT Cloud</NuxtLink
            >
          </li>
          <li v-if="!loggedIn">
            <NuxtLink to="/login" class="block px-4 py-2 hover:bg-green-600"
              >Login</NuxtLink
            >
          </li>
          <li v-if="loggedIn">
            <NuxtLink to="/dashboard" class="block px-4 py-2 hover:bg-green-600"
              >Dashboard</NuxtLink
            >
          </li>
          
        </ul>
      </div>
    </nav>
  </header>
</template>

<script lang="ts" setup>
const { loggedIn, user } = useHeroAuth().value;
const colorMode = useColorMode();
const isMenuOpen = ref(false);

const isDark = computed({
  get() {
    return colorMode.value === "dark";
  },
  set() {
    colorMode.preference = colorMode.value === "dark" ? "light" : "dark";
  },
});

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}
</script>

<style scoped></style>
