<script lang="ts" setup></script>

<template>
  <div>
    <Header></Header>
    <NuxtLoadingIndicator />

    <!-- Output page content -->
    <div class="w-full px-2 sm:px-4 md:container md:mx-auto">
      <slot />
    </div>
    <Footer></Footer>
  </div>
</template>

<style scoped>
.router-link-exact-active {
  color: #12b488;
}
</style>
